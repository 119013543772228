// // // Init all player container
// // let playerInfoList = [];

// window.addEventListener("load", () => {
//    // 2. This code loads the IFrame Player API code asynchronously.
//    // var tag = document.createElement("script");
//    // tag.src = "https://www.youtube.com/iframe_api";
//    // var firstScriptTag = document.getElementsByTagName("script")[0];
//    // firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
//    //    // Add iframe API script
//    //    // let tag = document.createElement("script");
//    //    // tag.src = "https://www.youtube.com/iframe_api";
//    //    // var firstScriptTag = document.getElementsByTagName("script")[0];
//    //    // firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
//    // createPlayer({
//    //    id: "myvideoyoutube",
//    //    height: "390",
//    //    width: "640",
//    //    videoId: "iIE_yzfXsXY",
//    // });
//    //    document.querySelectorAll(".youtube-player").forEach(youtubePlayer => {
//    //       console.log(youtubePlayer);
//    //       createPlayer({
//    //          id: youtubePlayer.id,
//    //          height: "390",
//    //          width: "640",
//    //          videoId: youtubePlayer.dataset.youtubeId,
//    //       });
//    //       // playerInfoList.push({
//    //       //    id: "player1",
//    //       //    height: "390",
//    //       //    width: "640",
//    //       //    videoId: "iIE_yzfXsXY",
//    //       // );
//    //    });
//    //    // loadPlayer();
//    //    // initYoutubeIframe(playerInfoList);
//    console.log(document.querySelectorAll(".block-quote__youtube"));
//    document.querySelectorAll(".block-quote__youtube").forEach(modal => {
//       let youtubePlayer = modal.querySelector(".youtube-player");

//       console.log(youtubePlayer.id);

//       let player = new YT.Player(youtubePlayer.id, {
//          height: "360",
//          width: "640",
//          videoId: youtubePlayer.dataset.youtubeId,
//       });

//       console.log(player);
//       // playerInfoList.push({
//       //    id: "player1",
//       //    height: "390",
//       //    width: "640",
//       //    videoId: "iIE_yzfXsXY",
//       // );
//    });
// });
// // function onYouTubeIframeAPIReady() {
// //    console.log("YouTube api is ready");

// //    document.querySelectorAll(".block-quote__youtube").forEach(modal => {
// //       let youtubePlayer = modal.querySelector(".youtube-player");

// //       console.log(youtubePlayer.id);

// //       let player = new YT.Player(youtubePlayer.id, {
// //          height: "360",
// //          width: "640",
// //          videoId: youtubePlayer.dataset.youtubeId,
// //       });

// //       console.log(player);
// //       // playerInfoList.push({
// //       //    id: "player1",
// //       //    height: "390",
// //       //    width: "640",
// //       //    videoId: "iIE_yzfXsXY",
// //       // );
// //    });
// // }

// function createPlayer(playerInfo) {
//    return new YT.Player(playerInfo.id, {
//       height: playerInfo.height,
//       width: playerInfo.width,
//       videoId: playerInfo.videoId,
//    });
// }
